<template>
  <div>
    <v-card flat style="background-color: var(--v-background-base) !important">
      <v-toolbar
        color="greyBase"
        dark
        :extended="!$vuetify.breakpoint.mobile"
        flat
        extension-height="55"
      >
        <v-toolbar-title>
          <b class="mr-3">Orders</b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn-toggle
          mandatory
          outlined
          :light="!$store.state.darkMode"
          v-model="toggleView"
          rounded
          color="blue"
        >
          <!-- <v-btn small outlined>
            <v-icon left small :color="toggleView == 0 ? 'blue' : 'primaryText'"
              >insights</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Dashboard</span
            >
          </v-btn> -->
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 0 ? 'blue' : 'primaryText'"
              >list</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Orders</span
            >
          </v-btn>
              <v-btn small outlined>
            <v-icon left small :color="toggleView == 1 ? 'blue' : 'primaryText'"
              >checklist</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)"
              >Itemised View</span
            >
          </v-btn>
          <v-btn small outlined>
            <v-icon left small :color="toggleView == 2 ? 'blue' : 'primaryText'"
              >fact_check</v-icon
            >
            <span style="text-transform: none; color: var(--v-primaryText-base)">Allocate Orders</span>
          </v-btn>
          
        </v-btn-toggle>
      </v-toolbar>

      <v-card
        class="mx-auto"
        :max-width="!$vuetify.breakpoint.mobile ? '94vw' : '100%'"
        
        :style="{
          'margin-top': !$vuetify.breakpoint.mobile ? '-64px' : '15px',
          'border-radius': !$vuetify.breakpoint.mobile ? '50px' : '0px',
        }"
      >
        <v-card-text
          :style="{
            height: !$vuetify.breakpoint.mobile ? '90vh' : '90vh',
            'overflow-y': 'auto',
          }"
        >
          <!-- <div v-if="toggleView == 0">
            <OrderDashboard />
          </div> -->
          <div v-if="toggleView == 0">
            <OrderTable />
          </div>
          <div v-else-if="toggleView == 1">
            <OrderLineItem />
          </div>
          <div v-else-if="toggleView == 2">
            <OrderAllocate />
          </div>
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>
<script>
// import OrderDashboard from "@/components/Orders/OrderDashboard.vue";
import OrderAllocate from '@/components/Stock/OverviewTabs/OrderAllocate.vue'
import OrderLineItem from "@/components/Orders/OrderLineItem.vue";
import OrderTable from "@/components/Orders/OrderTable.vue";
export default {
  components: {
    OrderAllocate,
    // OrderDashboard,
    OrderLineItem,
    OrderTable,
  },
  data: () => ({
    toggleView: 0,
  }),
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 70vh;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>