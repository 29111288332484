<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row justify="end">
          <v-col cols="12" sm="6" class="text-right">
            <v-chip
              style="height: 40px; border: none"
              outlined
              small
              class="mr-1"
              @click="changeHideCancelled"
            >
              <v-icon v-if="hideCancelled" small left color="green"
                >check</v-icon
              >
              <v-icon v-else small left color="red">cancel</v-icon>

              Hide Cancelled
            </v-chip>
            <v-chip
              style="height: 40px"
              outlined
              small
              class="pr-1 text-center"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon color="primary" @click="addOrder()"
                    ><v-icon>add_circle_outline</v-icon></v-btn
                  >
                </template>
                <span>Create Order</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    color="primary"
                    @click="openAvailableBookings()"
                    ><v-icon>post_add</v-icon></v-btn
                  >
                </template>
                <span>Create Order from Booking</span>
              </v-tooltip>

              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
              ></v-text-field>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <v-menu
                      offset-y
                      nudge-right
                      v-model="columnMenu"
                      :close-on-content-click="false"
                      style="height: 20vh; margin-right: 8vw"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" icon
                          ><v-icon>view_week</v-icon></v-btn
                        >
                      </template>
                      <v-card
                        style="
                          background: var(--v-modal-base) !important;
                          border-radius: 20px;
                        "
                      >
                        <v-card-text>
                          <v-list
                            style="
                              overflow: hidden;
                              column-count: 3;
                              width: 400px;
                              height: fit-content;
                            "
                          >
                            <v-checkbox
                              v-for="(item, index) in headers.filter(
                                (x) => x.value != 'action'
                              )"
                              :key="index"
                              v-model="item.show"
                              @change="toggleColumn(item, index)"
                              dense
                              :label="item.text"
                              hide-details
                            ></v-checkbox>
                          </v-list>
                          <v-row class="pt-5 pb-4" style="overflow-y: hidden">
                            <v-col
                              cols="12"
                              md="12"
                              sm="12"
                              lg="12"
                              class="d-flex justify-center"
                            >
                              <v-btn
                                @click="updateUserLayout"
                                outlined
                                color="primary"
                                >SAVE</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </div>
                </template>
                Select Columns
              </v-tooltip>
            </v-chip>
          </v-col>
        </v-row>
        <v-card-text
          style="max-width: 100%; overflow-x: auto"
          class="my-0"
          :key="headerKey"
        >
          <div
            :style="{ width: tableWidth }"
            class="mt-2"
            v-if="displayData.length > 0"
          >
            <table :width="'100%'">
              <tr class="mx-0 px-0">
                <draggable
                  v-model="selectedHeaders"
                  tag="tr"
                  :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                  :animation="200"
                  @change="updateUserLayout()"
                  ghost-class="ghost-card"
                  @start="columnDrag = true"
                  @end="columnDrag = false"
                >
                  <td
                    class="text-center mx-0 px-0"
                    style="
                      cursor: grab;
                      border-right: 1px solid grey;
                      min-height: 20px;
                      min-width: 20px;
                    "
                    v-for="(header, index) in selectedHeaders"
                    :key="index"
                    :width="header.width ?? '150px'"
                  >
                    <div>
                      <v-row align="center" justify="center" class="py-2">
                        <b style="font-size: 12px">{{ header.text }}</b>
                        <v-btn
                          v-if="!header.disableSort"
                          class="ml-1"
                          icon
                          x-small
                          @click="changeSortBy(header.value)"
                        >
                          <v-icon
                            v-if="sortBy != header.value"
                            small
                            color="grey"
                            >arrow_upward</v-icon
                          >
                          <v-icon v-else-if="sortDesc" small
                            >arrow_downward</v-icon
                          >
                          <v-icon v-else small>arrow_upward</v-icon>
                        </v-btn>
                        <v-badge
                          v-if="header.filterable"
                          :content="
                            filters[header.value]
                              ? filters[header.value].length
                              : false
                          "
                          :value="
                            filters[header.value] &&
                            filters[header.value].length > 0
                          "
                          color="primary"
                          offset-y="10"
                        >
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                x-small
                                color="grey"
                                icon
                                ><v-icon small>filter_alt</v-icon></v-btn
                              >
                            </template>
                            <v-card
                              style="
                                background: var(--v-modal-base) !important;
                                border-radius: 20px;
                              "
                            >
                              <v-card-text>
                                <v-list
                                  dense
                                  style="
                                    background: var(--v-modal-base) !important;
                                    border-radius: 20px;
                                  "
                                >
                                  <v-text-field
                                    hide-details
                                    rounded
                                    dense
                                    outlined
                                    clearable
                                    prepend-inner-icon="search"
                                    placeholder="Search"
                                    autofocus
                                    v-model="searchHeaders[header.value]"
                                    @input="updateSearchKey()"
                                  ></v-text-field>
                                  <v-list
                                    dense
                                    style="
                                      max-height: 50vh;
                                      overflow-y: auto;
                                      background: var(
                                        --v-modal-base
                                      ) !important;
                                    "
                                    :key="searchKey"
                                  >
                                    <v-list-item
                                      v-for="(item, index) in headerOptions(
                                        header.value
                                      )"
                                      :key="index"
                                    >
                                      <v-list-item-action class="pr-0">
                                        <v-checkbox
                                          v-model="filters[header.value]"
                                          :value="item.value"
                                          hide-details
                                          dense
                                          @change="calculateFilteredResults()"
                                        ></v-checkbox>
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          {{ item.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="
                                        headerOptions(header.value).length == 0
                                      "
                                    >
                                      <v-list-item-content class="text-center">
                                        <span style="font-size: 12px"
                                          >No results found</span
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                  </v-list>
                                </v-list>
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </v-badge>
                      </v-row>
                    </div>
                  </td>
                </draggable>
              </tr>
            </table>
            <v-divider></v-divider>
            <div
              class="mt-1 pt-1"
              :style="{ width: '100%' }"
              v-if="displayData.length > 0"
            >
              <v-virtual-scroll
                v-if="displayData.length > 0"
                :bench="10"
                :items="displayData"
                :height="calculatedHeight"
                :width="'100%'"
                item-height="35"
                class="px-1"
              >
                <template v-slot:default="{ item }">
                  <div
                    class="text-center my-0 py-0"
                    :style="{
                      display: 'flex',
                      'flex-direction': 'row',
                      width: '100%',
                      'background-color':
                        item.id &&
                        selectedOrderItem &&
                        selectedOrderItem.id == item.id
                          ? 'rgba(12, 97, 24, 0.6)'
                          : 'transparent',
                    }"
                  >
                    <div
                      v-if="item.displayType == 'header'"
                      style="width: 100%"
                    >
                      <td class="text-center mt-0 mb-0 pb-0 mr-0 pr-0">
                        {{ item.title }}
                        <v-chip
                          class="mx-1"
                          outlined
                          style="border: none"
                          small
                        >
                          <v-icon small left color="teal">label</v-icon>
                          Orders
                          <v-chip right x-small class="ml-2">{{
                            item.orders
                          }}</v-chip>
                        </v-chip>
                        <v-chip
                          class="mx-1"
                          small
                          outlined
                          style="border: none"
                        >
                          <v-icon small left color="blue-grey">widgets</v-icon>
                          Containers
                          <v-chip x-small class="ml-2" right>{{
                            item.containers
                          }}</v-chip>
                        </v-chip>
                      </td>
                    </div>
                    <div v-else :style="{ width: '100%' }" class="my-0 py-0">
                      <td
                        v-for="(header, index) in selectedHeaders"
                        :key="index"
                        :style="{ width: header.width ?? '150px' }"
                        class="text-center my-0 py-0"
                      >
                        <div v-if="header.value == 'actions'">
                          <v-btn icon @click="viewOrder(item)" small>
                            <v-icon small>launch</v-icon>
                          </v-btn>
                        </div>
                        <div v-else-if="header.value == 'userFullName'">
                          <v-tooltip top v-if="item.orderUser">
                            <template v-slot:activator="{ on }">
                              <v-avatar
                                size="24"
                                class="ml-1"
                                v-on="on"
                                :color="
                                  item.orderUser.avatar ? 'white' : 'secondary'
                                "
                                style="cursor: pointer"
                                @click="
                                  addToFilter(item.userFullName, 'userFullName')
                                "
                              >
                                <v-img
                                  v-if="item.orderUser.avatar"
                                  contain
                                  :src="item.orderUser.avatar"
                                ></v-img>
                                <h4 v-else>
                                  {{ item.orderUser.firstname.charAt(0) }}
                                </h4>
                              </v-avatar>
                            </template>
                            <span style="font-size: 12px"
                              >{{ item.orderUser.firstname }}
                              {{ item.orderUser.surname }}</span
                            >
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'orderStatus'"
                          class="text-center"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="statusColor(item.orderStatus)"
                                small
                                icon
                                v-on="on"
                                @click="
                                  addToFilter(item.orderStatus, 'orderStatus')
                                "
                              >
                                <v-icon>{{
                                  statusIcon(item.orderStatus)
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.orderStatus)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'stockAllocationStatus'"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-progress-circular
                                v-on="on"
                                :value="item.allocatedProgress"
                                size="20"
                                :color="
                                  item.totalPallets == 0
                                    ? 'grey'
                                    : item.allocatedProgress < 20
                                    ? 'red'
                                    : item.allocatedProgress < 50
                                    ? 'orange'
                                    : item.allocatedProgress < 100
                                    ? 'blue'
                                    : 'success'
                                "
                              >
                              </v-progress-circular>
                            </template>
                            Allocated: {{ item.allocatedPallets }}/{{
                              item.totalPallets
                            }}
                          </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'stockStuffedStatus'">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-progress-circular
                                v-on="on"
                                :value="item.loadedProgress"
                                size="20"
                                :color="
                                  item.loadedProgress < 20
                                    ? 'red'
                                    : item.loadedProgress < 50
                                    ? 'orange'
                                    : item.loadedProgress < 100
                                    ? 'blue'
                                    : 'success'
                                "
                              >
                              </v-progress-circular>
                            </template>
                            Stuffed Containers: {{ item.loadedUnits }}/{{
                              item.totalUnits
                            }}
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'state'"
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.state">
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="bookingStatusColor(item.state)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.state, 'state')"
                              >
                                <v-icon>{{
                                  bookingStatusIcon(item.state)
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.state)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'type'"
                          class="text-center"
                          :style="{ width: header.width ?? '150px' }"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="bookingTypeColor(item.type)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.type, 'type')"
                              >
                                <v-icon>{{
                                  bookingTypeIcon(item.type)
                                }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.type)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'vesselVoyage'"
                          class="text-left"
                          :style="{ width: header.width ?? '150px' }"
                        >
                          <v-chip
                            v-if="item.vesselVoyage"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.vesselVoyage, 'vesselVoyage')
                            "
                            style="border: none; font-size: 11px"
                            ><span>{{ item.vesselVoyage }}</span>
                          </v-chip>
                        </div>
                        <div
                          v-else-if="header.value == 'portOfLoadValue'"
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.portOfLoadValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.portOfLoadValue,
                                    'portOfLoadValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.portOfLoadValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.portOfLoadCity
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'finalDestinationValue'"
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.finalDestinationValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.finalDestinationValue,
                                    'finalDestinationValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.finalDestinationValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.finalDestinationCity
                            }}</span>
                          </v-tooltip>
                        </div>

                        <div
                          v-else-if="header.value == 'consigneeName'"
                          :style="{ width: header.width ?? '150px' }"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.consignee">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.consignee"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(
                                    item.consigneeName,
                                    'consigneeName'
                                  )
                                "
                                v-on="on"
                                style="border: none"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.consignee && item.consignee.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.consignee.logo"
                                    :src="item.consignee.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.consigneeName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.consigneeName }}
                              </v-chip>
                            </template>
                            <span>{{ item.consignee.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'forwarderName'"
                          :style="{ width: header.width ?? '150px' }"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.forwarder">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.forwarder"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(
                                    item.forwarderName,
                                    'forwarderName'
                                  )
                                "
                                v-on="on"
                                style="border: none"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.forwarder && item.forwarder.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.forwarder.logo"
                                    :src="item.forwarder.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.forwarderName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.forwarderName }}
                              </v-chip>
                            </template>
                            <span>{{ item.forwarder.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'transporterName'"
                          :style="{ width: header.width ?? '150px' }"
                          :class="
                            item.transporter ? 'text-left' : 'text-center'
                          "
                        >
                          <v-tooltip top v-if="item.transporter">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.transporter"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(
                                    item.transporterName,
                                    'transporterName'
                                  )
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.transporter && item.transporter.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.transporter.logo"
                                    :src="item.transporter.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.transporterName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.transporterName }}
                              </v-chip>
                            </template>
                            <span>{{ item.transporter.name }}</span>
                          </v-tooltip>
                          <v-btn
                            color="grey"
                            small
                            icon
                            v-else
                            @click="assignContainers([item])"
                            ><v-icon small>add_circle_outline</v-icon></v-btn
                          >
                        </div>

                        <div
                          v-else-if="header.value == 'customerName'"
                          :style="{ width: header.width ?? '150px' }"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.orderCustomer">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.orderCustomer"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.customerName, 'customerName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.orderCustomer &&
                                    item.orderCustomer.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.orderCustomer.logo"
                                    :src="item.orderCustomer.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.customerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.customerName }}
                              </v-chip>
                            </template>
                            <span>{{ item.orderCustomer.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'buyerName'"
                          :style="{ width: header.width ?? '150px' }"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.buyer">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.buyer"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.buyerName, 'buyerName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.buyer && item.buyer.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.buyer.logo"
                                    :src="item.buyer.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.buyerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.buyerName }}
                              </v-chip>
                            </template>
                            <span>{{ item.buyer.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shipperName'"
                          :style="{ width: header.width ?? '150px' }"
                          class="text-left"
                        >
                          <v-tooltip top v-if="item.shipper">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.shipper"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.shipperName, 'shipperName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.shipper && item.shipper.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.shipper.logo"
                                    :src="item.shipper.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.shipperName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.shipperName }}
                              </v-chip>
                            </template>
                            <span>{{ item.shipper.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shippingLineName'"
                          class="text-left"
                          :style="{ width: header.width ?? '150px' }"
                        >
                          <v-chip
                            v-if="item.shippingLine"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(
                                item.shippingLineName,
                                'shippingLineName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.shippingLine && item.shippingLine.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.shippingLine.logo"
                                :src="item.shippingLine.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shippingLineName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shippingLineName }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'transportStatus'">
                          <v-menu offset-y v-if="item[header.value]">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                small
                                class="primaryText--text"
                                :color="getStatusColor(item.transportStatus)"
                                v-on="on"
                                style="border: none"
                              >
                                {{ item[header.value] }}
                                <v-icon small right>expand_more</v-icon>
                              </v-chip>
                            </template>
                            <v-list style="overflow: hidden" dense>
                              <v-list-item
                                v-for="(status, index) in transportStatuses"
                                :key="index"
                                style="height: 35px"
                                @click="setStatus(item, status)"
                              >
                                <v-list-item-action>
                                  <v-icon :color="status.color"
                                    >fiber_manual_record</v-icon
                                  >
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ status.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div v-else-if="header.value == 'etd'">
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item[header.value], header.value)
                            "
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'vesselLegs'">
                          <v-btn
                            v-if="item.sailingSchedule"
                            icon
                            small
                            outlined
                            @click="viewBookingLegs(item)"
                            :color="
                              item.sailingSchedule.legs == 1
                                ? 'success'
                                : 'blue'
                            "
                            >{{ item.sailingSchedule.legs }}</v-btn
                          >
                        </div>
                        <div v-else-if="header.value == 'numContainers'">
                          <v-chip
                            v-if="item[header.value]"
                            small
                            @click="
                              addToFilter(item[header.value], header.value)
                            "
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'eta'">
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item[header.value], header.value)
                            "
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>

                        <div v-else-if="header.value == 'movementType'">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.movementType, header.value)
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  :color="
                                    item.movementType == 'EXPORT'
                                      ? 'deep-orange'
                                      : 'blue'
                                  "
                                  left
                                >
                                  <h4 style="color: white">
                                    {{ item.movementType.charAt(0) }}
                                  </h4>
                                </v-avatar>
                                {{ item.orderNo }}
                              </v-chip>
                            </template>
                            <span>
                              {{
                                "Created By : " +
                                item.orderUser.firstname +
                                " " +
                                item.orderUser.surname +
                                " : " +
                                formatDateTime(item.createdAt)
                              }}
                            </span>
                          </v-tooltip>
                        </div>
                        <div v-else>
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item[header.value], header.value)
                            "
                            style="border: none"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                      </td>
                    </div>
                  </div>

                  <v-divider class="my-0 py-0 mt-1"></v-divider>
                </template>
              </v-virtual-scroll>
            </div>
          </div>
          <div class="container" v-if="!loading && displayData.length == 0">
            <h3 style="color: grey">No Results Found</h3>
          </div>
          <div class="container" v-else-if="loading && displayData.length == 0">
            <valhalla-loading height="500px" />
          </div>
        </v-card-text>
      </v-card-text>
    </v-card>

    <v-dialog v-model="orderModal" persistent width="95vw">
      <CreateOrder
        :order="order"
        :organisations="organisations"
        :loadingRelated="loadingOrganisations"
        @close="(orderModal = false), (order = {})"
        @refresh="getOrders"
      />
    </v-dialog>

    <v-dialog v-model="bookingModal" width="800px">
      <v-card :loading="loadingBookings">
        <v-toolbar dense flat>
          <v-toolbar-title>Available Bookings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="bookingModal = false">X</v-btn>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="searchBookings"
            placeholder="Search"
            outlined
            dense
            clearable
            prepend-inner-icon="search"
          ></v-text-field>
          <div
            class="loadingContainer"
            v-if="!loadingBookings && bookings.data.length == 0"
          >
            <h3 style="color: grey">No Available Bookings</h3>
          </div>
          <div class="loadingContainer" v-else-if="loadingBookings">
            <h3 style="color: grey">Loading...</h3>
          </div>
          <v-virtual-scroll
            v-else-if="bookings.data.length > 0"
            :items="availableBookings"
            item-height="200"
            height="500"
          >
            <template v-slot:default="{ item }">
              <v-card
                style="
                  border-radius: 20px;
                  background: var(--v-component-base) !important;
                "
                @click="confirmBookingToOrder(item)"
              >
              <v-toolbar flat dense>
                <v-toolbar-title style="font-size: 14px">
                  {{ item.orderNo }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip small outlined color="primary" v-if="item.vessel">
                  <v-icon small left>directions_boat</v-icon>
                  {{ item.vessel.name }}
                  <span right>{{ item.mainCarriageConveyanceNumber }}</span>
                </v-chip>
              </v-toolbar>
                <v-card-text>
                  <v-row justify="center">
                    <v-col cols="12" sm="4">
                      <v-list dense subheader>
                        <v-subheader>Parties</v-subheader>
                        <v-divider></v-divider>
                        <!-- Shipper -->
                        <v-list-item style="height: 35px" v-if="item.shipper">
                          <v-list-item-action>
                            <v-avatar
                              :color="item.shipper.logo ? 'white' : 'secondary'"
                              size="32"
                            >
                              <v-img
                                v-if="item.shipper.logo"
                                :src="item.shipper.logo"
                                contain
                              ></v-img>
                              <h3 v-else>{{ item.shipper.name.charAt(0) }}</h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">{{
                              item.shipper.name
                            }}</v-list-item-title>
                             <v-list-item-subtitle style="font-size: 12px">
                              Shipper
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
<!-- Forwarder -->
                        <v-list-item style="height: 35px" v-if="item.forwarder">
                          <v-list-item-action>
                            <v-avatar
                              :color="item.forwarder.logo ? 'white' : 'secondary'"
                              size="32"
                            >
                              <v-img
                                v-if="item.forwarder.logo"
                                :src="item.forwarder.logo"
                                contain
                              ></v-img>
                              <h3 v-else>{{ item.forwarder.name.charAt(0) }}</h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">{{
                              item.forwarder.name
                            }}</v-list-item-title>
                             <v-list-item-subtitle style="font-size: 12px">
                              Forwarder
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <!-- Buyer -->
                        <v-list-item style="height: 35px" v-if="item.buyer">
                          <v-list-item-action>
                            <v-avatar
                              :color="item.buyer.logo ? 'white' : 'secondary'"
                              size="32"
                            >
                              <v-img
                                v-if="item.buyer.logo"
                                :src="item.buyer.logo"
                                contain
                              ></v-img>
                              <h3 v-else>{{ item.buyer.name.charAt(0) }}</h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">{{
                              item.buyer.name
                            }}</v-list-item-title>
                             <v-list-item-subtitle style="font-size: 12px">
                              Buyer
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                          <!-- Consignee -->
                          <v-list-item style="height: 35px" v-if="item.consignee">
                          <v-list-item-action>
                            <v-avatar
                              :color="item.consignee.logo ? 'white' : 'secondary'"
                              size="32"
                            >
                              <v-img
                                v-if="item.consignee.logo"
                                :src="item.consignee.logo"
                                contain
                              ></v-img>
                              <h3 v-else>{{ item.consignee.name.charAt(0) }}</h3>
                            </v-avatar>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">{{
                              item.consignee.name
                            }}</v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Consignee
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="4">
                      <v-list dense subheader>
                        <v-subheader>Details</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item style="height: 35px">
                          <v-list-item-action>
                            <v-icon small color="grey">anchor</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              <span v-if="item.portOfLoadValue">
                                {{ item.portOfLoadValue }}
                              </span>
                          <span v-else>-</span>
                        </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Port Of Load
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-avatar size="28" v-if="item.portOfLoadValue">
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item style="height: 35px">
                          <v-list-item-action>
                            <v-icon small color="grey">anchor</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              <span v-if="item.portOfDischargeValue">
                                {{ item.portOfDischargeValue }}
                              </span>
                          <span v-else>-</span>
                        </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Port Of Discharge
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-avatar size="28" v-if="item.portOfDischargeValue">
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfDischargeValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item style="height: 35px">
                          <v-list-item-action>
                            <v-icon small color="grey">location_on</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              <span v-if="item.finalDestinationValue">
                                {{ item.finalDestinationValue }}
                              </span>
                          <span v-else>-</span>
                        </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Final Destination
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-avatar size="28" v-if="item.finalDestinationValue">
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                          </v-list-item-action>
                        </v-list-item>
                        <v-list-item style="height:35px">
                        <v-list-item-action>
                          <v-icon small color="grey">event</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">
                            {{ formatDate(item.etd) }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            ETD
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                        <v-list-item style="height:35px">
                        <v-list-item-action>
                          <v-icon small color="grey">event</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title style="font-size: 12px">
                            {{ formatDate(item.eta) }}
                          </v-list-item-title>
                          <v-list-item-subtitle style="font-size: 12px">
                            ETA
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="4">
                      <v-list dense subheader>
                        <v-subheader>Cargo</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item style="height: 35px">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              {{ item.numContainers }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Containers
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                        <v-subheader>References</v-subheader>
                        <v-divider></v-divider>
                        <v-list-item style="height: 35px">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              {{ item.shipperReferenceNo }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Shipper Ref. No
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="height: 35px">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              {{ item.consigneeReferenceNo }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Consignee Ref. No
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          </v-list-item>
                          <v-list-item style="height: 35px">
                          <v-list-item-content>
                            <v-list-item-title style="font-size: 12px">
                              {{ item.carrierReferenceNumber }}
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 12px">
                              Carrier Ref. No
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          </v-list-item>


                      </v-list>
</v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-virtual-scroll>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmBookingConversionModal" width="500px">
      <v-card :loading="convertingBooking">
        <v-toolbar dense flat color="transparent">
          <v-spacer></v-spacer>
          <!-- <v-btn text @click="confirmSelectedPointModal = false">X</v-btn> -->
        </v-toolbar>
        <v-card-text>
          <p style="font-size: 14px">
            Are you sure you would like to convert <b>{{selectedBooking.orderNo}}</b> into an Order?
          </p>
          <v-row justify="center" class="mt-5 mb-3">
            <v-btn
              color="red"
              class="mx-2"
              text
              small
              @click="confirmBookingConversionModal = false"
              >No</v-btn
            >
            <v-btn
              color="success"
              class="mx-2"
              text
              small
              @click="convertBooking(selectedBooking)"
              >Yes</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
      </v-dialog>
  </div>
</template>
  <script>
import CreateOrder from "./CreateOrder.vue";
import dateFormat from "dateformat";
import draggable from "vuedraggable";
import * as moment from "moment";
export default {
  components: {
    CreateOrder,
    draggable,
  },
  data: () => ({
    bookings: { data: [] },
    bookingModal: false,
    bookingQuery: undefined,
    bookingTimeout: undefined,
    bookingStatus: [
      { name: "Draft", icon: "edit_note", color: "#F57C00" },
      { name: "Pending", icon: "pending", color: "#009688" },
      { name: "Requested", icon: "verified", color: "#2196F3" },
      { name: "Confirmed", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Ghost", icon: "help", color: "#607D8B" },
      { name: "Cancelled", icon: "cancel", color: "#F44336" },
      { name: "Rejected", icon: "warning", color: "#F44336" },
    ],
    columnDrag: false,
    columnMenu: false,
    columnKey: 500,
    convertingBooking: false,
    confirmBookingConversionModal: false,
    displayHeaders: [],
    filteredResults: [],
    filters: null,
    filteredValues: [],
    hideCancelled: true,
    headerKey: 400,
    headers: [
      {
        text: "Action",
        align: "center",
        disableSort: true,
        disableFilter: true,
        value: "actions",
        width: "100px",
        show: true,
        required: true,
      },

      {
        text: "Status",
        value: "orderStatus",
        align: "center",
        width: "100px",
        filterable: true,
        show: true,
      },
      {
        text: "Booking",
        value: "state",
        align: "center",
        width: "100px",
        filterable: true,
        show: true,
      },
      {
        text: "Allocation",
        value: "stockAllocationStatus",
        align: "center",
        width: "120px",
        filterable: false,
        show: true,
      },
      // {
      //   text: "Stuffed",
      //   value: "stockStuffedStatus",
      //   align: "center",
      //   width: "120px",
      //   filterable: false,
      //   show: true,
      // },
      {
        text: "Order No",
        value: "movementType",
        align: "center",
        filterable: true,
        show: true,
      },
      {
        text: "Shipper Ref. No",
        value: "shipperReferenceNo",
        align: "center",
        width: "200px",
        filterable: false,
        show: true,
      },
      {
        text: "Consignee Ref. No",
        value: "consigneeReferenceNo",
        width: "200px",
        align: "center",
        filterable: false,
        show: true,
      },
      {
        text: "Containers",
        value: "numContainers",
        align: "center",
        width: "125px",
        show: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        align: "center",
        width: "220px",
        show: true,
      },
      {
        text: "ETD",
        value: "etd",
        align: "center",
        width: "100px",
      },
      {
        text: "Customer",
        value: "customerName",
        width: "220px",
        filterable: true,
        show: true,
      },
      {
        text: "Buyer",
        value: "buyerName",
        width: "220px",
        filterable: true,
        show: true,
      },
      {
        text: "Shipper",
        value: "shipperName",
        width: "220px",
        filterable: true,
        show: true,
      },
      {
        text: "Consignee",
        value: "consigneeName",
        width: "220px",
        filterable: true,
        show: true,
      },
      {
        text: "Forwarder",
        value: "forwarderName",
        width: "220px",
        filterable: true,
        show: true,
      },
      {
        text: "Carrier",
        value: "shippingLineName",
        align: "left",
        width: "120px",
        show: true,
      },
      {
        text: "Week",
        value: "sailingWeek",
        align: "center",
        width: "120px",
        show: true,
      },
      {
        text: "POL",
        value: "portOfLoadValue",
        align: "center",
        width: "100px",
        show: true,
      },

      // {
      //     text: 'POD',
      //     value: 'portOfDischargeValue',
      //     align: 'center',
      //     filterable: true
      // },
      {
        text: "Dest.",
        value: "finalDestinationValue",
        align: "center",
        width: "100px",
        show: true,
      },
      {
        text: "ETA",
        value: "eta",
        align: "center",
        width: "100px",
        show: false,
      },

      {
        text: "Carrier Ref. No",
        value: "carrierReferenceNumber",
        align: "center",
        filterable: false,
        show: false,
      },

      {
        text: "User",
        align: "center",
        disableSort: false,
        disableFilter: false,
        value: "userFullName",
        width: "100px",
        show: false,
      },
    ],
    loading: false,
    loadingBookings: false,
    loadingOrganisations: false,
    order: {},
    orderModal: false,
    orders: {
      data: [],
      total: 0,
    },
    organisations: {
      total: 0,
      data: [],
    },
    orderQuery: undefined,
    orderStatus: [
      { name: "Planned", icon: "schedule", color: "#F57C00" },
      { name: "In Progress", icon: "pending", color: "blue" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "Confirmed", icon: "verified", color: "teal" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      // { name: "Cancelled", icon: "cancel", color: "#F44336" }
    ],
    orderTimeout: undefined,
    search: null,
    searchBookings: null,
    searchHeaders: {},
    searchKey: 100,
    selectedBooking: {},
    selectedOrderItem: {},
    selectedHeaders: [],
    showMenu: false,
    sortBy: null,
    sortDesc: false,
    updateFilterValue: 350,
    x: null,
    y: null,
  }),
  computed: {
    availableBookings(){
      let result = this.bookings.data;
      if(this.searchBookings){
        result = result.filter(x=>
        x.orderNo.toLowerCase().includes(this.searchBookings.toLowerCase()) ||
        (x.consigneeName && x.consigneeName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.shipperName && x.shipperName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.buyerName && x.buyerName.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.vessel && x.vessel.name.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.mainCarriageConveyanceNumber && x.mainCarriageConveyanceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.carrierReferenceNumber && x.carrierReferenceNumber.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.shipperReferenceNo && x.shipperReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase())) ||
        (x.consigneeReferenceNo && x.consigneeReferenceNo.toLowerCase().includes(this.searchBookings.toLowerCase()))
      )
      }
      return result
    },
    calculatedHeight() {
      let height = window.innerHeight;
      return height * 0.68 + "px";
    },
    displayData() {
      let result = this.filteredResults;
      if (this.search) {
        result = result.filter(
          (x) =>
            (x.orderNo &&
              x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.shipperName &&
              x.shipperName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeName &&
              x.consigneeName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shippingLineName &&
              x.shippingLineName
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.vesselVoyage &&
              x.vesselVoyage
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.regimeCode &&
              x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.portOfLoadValue &&
              x.portOfLoadValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.portOfLoadCity &&
              x.portOfLoadCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationValue &&
              x.finalDestinationValue
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.finalDestinationCity &&
              x.finalDestinationCity
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.carrierReferenceNumber &&
              x.carrierReferenceNumber
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.shipperReferenceNo &&
              x.shipperReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.consigneeReferenceNo &&
              x.consigneeReferenceNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.customerName &&
              x.customerName.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      // result.forEach(x=>{
      //   x.weekYear = x.sailingWeek ? x.sailingWeek + ' - ' + x.sailingWeekYear: 'No Week Specified'
      // })
      let uniqueWeeks = [...new Set(result.map((x) => x.sailingWeek))];
      // uniqueWeeks.sort()
      // console.log(uniqueWeeks)
      let final = [];
      for (let i = 0; i < uniqueWeeks.length; i++) {
        // let week = uniqueWeeks[i].split('-')[0].trim()
        // let year = uniqueWeeks[i].split('-')[1].trim()
        // let findFirstIndex = result.findIndex(x=>x.sailingWeek == week)
        // console.log(findFirstIndex, week, year, uniqueWeeks[i])
        let filtered = result.filter((x) => x.sailingWeek == uniqueWeeks[i]);
        if (this.sortBy) {
          filtered = filtered.sort((a, b) => {
            if (this.sortDesc) {
              return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
            } else {
              return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
            }
          });
        }
        final = [
          ...final,
          {
            displayType: "header",
            title: uniqueWeeks[i],
            orders: filtered.length,
            containers: filtered
              .map((x) => x.numContainers)
              .reduce((a, b) => a + b, 0),
          },
          ...filtered,
        ];
        // result.splice(findFirstIndex-1, 0, {
        //   displayType: 'header',
        //   title: 'Week: ' + uniqueWeeks[i],
        //   orders: filtered.length,
        //   containers: filtered.map(x=>x.numContainers).reduce((a,b)=>a+b,0)
        // });
      }

      return final;
    },
    tableWidth() {
      let result = 0;
      this.selectedHeaders.forEach((x) => {
        result += parseInt(x.width ? x.width.replace("px", "") : 150);
      });
      return result + "px";
    },
  },
  created() {
    if (localStorage.orderHeaders) {
      let activeHeaders = JSON.parse(localStorage.orderHeaders);
      activeHeaders = activeHeaders.map((x) => x.value);
      this.headers.forEach((x) => {
        if (activeHeaders.includes(x.value)) {
          x.show = true;
        } else {
          x.show = false;
        }
      });
      this.selectedHeaders = this.headers.filter((x) => x.show || x.required);
    } else {
      this.selectedHeaders = this.headers.filter((x) => x.show || x.required);
    }
    let visible = this.selectedHeaders.map((x) => x.value);
    this.headers.forEach((x) => {
      if (visible.includes(x.value)) {
        x.show = true;
      } else {
        x.show = false;
      }
    });
    if (!this.filters) {
      this.filters = {};
      this.resetFilters();
    }
  },
  async mounted() {
    this.load();
  },
  methods: {
    addOrder() {
      this.order = {
        recordType: "ORDER",
        orderStatus: "Planned",
        status: "Draft",
        transportType: "Container",
        movementType: "EXPORT",
        type: "LOGISTICS",
        modeOfTransport: "OCEAN",
        serviceType: "FCL",
        bookingContainerItems: [],
        plannedYear: new Date().getFullYear(),
        plannedWeek: moment().isoWeek(),
      };
      this.orderModal = true;
    },
    addToFilter(value, field) {
      if (this.filters[field].includes(value)) {
        this.filters[field] = this.filters[field].filter((x) => x != value);
      } else {
        this.filters[field].push(value);
      }
      this.updateFilterValue++;
      localStorage.setItem(
        "orderFilters_" + this.$store.state.currentOrg.id,
        JSON.stringify(this.filters)
      );
      let keys = Object.keys(this.filters);
      let result = [];
      keys.forEach((x) => {
        result = [
          ...result,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = result;
      this.calculateFilteredResults();
    },
    calculateFilteredResults() {
      let filerKeys = Object.keys(this.filters);
      let filterResult = [];
      filerKeys.forEach((x) => {
        filterResult = [
          ...filterResult,
          ...this.filters[x].map((y) => ({
            field: x,
            fieldName: this.headers.find((z) => z.value == x).text,
            value: y,
          })),
        ];
      });
      this.filteredValues = filterResult;
      let result = [...this.orders.data];
      let keys = Object.keys(this.filters);
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.filters[key] && this.filters[key].length > 0) {
          result = result.filter((x) => this.filters[key].includes(x[key]));
        }
      }
      if (this.hideCancelled) {
        result = result.filter(
          (x) => x.orderStatus != "Cancelled" && x.state != "Cancelled"
        );
      }
      this.filteredResults = result;
    },
    changeHideCancelled() {
      this.hideCancelled = !this.hideCancelled;
      this.calculateFilteredResults();
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
    },
    async load() {
      this.getOrders();
      await this.getRelations();
    },
    confirmBookingToOrder(booking){
      this.selectedBooking = booking;
      this.confirmBookingConversionModal = true
    },
    async convertBooking(booking) {
      try {
        this.convertingBooking = true;
      let result = await this.$API.convertBookingToOrder(booking.id);
      this.convertingBooking = false;
      this.confirmBookingConversionModal = false;
      this.$message.success('Booking '+booking.orderNo+' converted to order successfully!')
      this.getOrders();
      this.getBookings();
      } catch(e){
        this.$message.error('Error converting booking to order')
        this.convertingBooking = false
      }
      
    },
    formatDate(date) {
      let result = null;
      if (date) {
        result = dateFormat(new Date(date), "dd-mm-yyyy");
      }
      return result;
    },
    formatDateTime(date) {
      let result = null;
      if (date) {
        result = dateFormat(date, "yyyy-mm-dd HH:MM");
      }
      return result;
    },
    async getBookings() {
      if (this.bookingTimeout) {
        clearTimeout(this.bookingTimeout);
      }
      if (this.loadingBookings && this.bookingQuery) {
        this.bookingQuery.abort();
      }
      this.loadingBookings = true;
      let params = {
        queryType: "OrderOverview",
        dates: [
          new Date(new Date().setDate(new Date().getDate() - 60))
            .toISOString()
            .substring(0, 10),
          new Date(new Date().setDate(new Date().getDate() + 30))
            .toISOString()
            .substring(0, 10),
        ],
      };
      this.bookingTimeout = setTimeout(async () => {
        this.bookingQuery = new AbortController();
        const signal = this.bookingQuery.signal;
        this.bookings = await this.$API.getBookingOverview({
          params: params,
          signal,
        });
        this.loadingBookings = false;
      }, 500);
    },
    async getOrders() {
      if (this.orderTimeout) {
        clearTimeout(this.orderTimeout);
      }
      if (this.loading && this.orderQuery) {
        this.orderQuery.abort();
      }
      this.loading = true;
      let params = {};
      params.recordType = "ORDER";
      this.orderTimeout = setTimeout(async () => {
        this.orderQuery = new AbortController();
        const signal = this.orderQuery.signal;
        this.orders = await this.$API.getOrders({
          params: params,
          signal,
        });
        this.calculateFilteredResults();
        this.loading = false;
      }, 500);
    },
    async getRelations() {
      this.loadingOrganisations = true;
      if (
        localStorage["relatedOrganisations_" + this.$store.state.currentOrg.id]
      ) {
        this.organisations = JSON.parse(
          localStorage[
            "relatedOrganisations_" + this.$store.state.currentOrg.id
          ]
        );
      }
      this.organisations = await this.$API.getRelationBasic({
        // params: this.params,
      });
      let findCurrentOrg = this.organisations.data.find(
        (x) => x.relatedOrganisationId == this.$store.state.currentOrg.id
      );
      if (!findCurrentOrg) {
        this.organisations.data.unshift({
          relatedOrganisationId: this.$store.state.currentOrg.id,
          relatedOrganisation: this.$store.state.currentOrg,
        });
      }
      this.loadingOrganisations = false;
    },
    openAvailableBookings() {
      this.getBookings();
      this.bookingModal = true;
    },
    statusColor(status) {
      if (status) {
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    statusIcon(status) {
      if (status) {
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    bookingStatusColor(status) {
      if (status) {
        let color = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else return "grey";
    },
    bookingStatusIcon(status) {
      if (status) {
        let find = this.bookingStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return "help";
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.orders.data
            // .filter(x => x[value])
            .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      if (this.searchHeaders[value]) {
        uniqueValues = uniqueValues.filter(
          (x) =>
            x &&
            x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
        );
      }
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      // if (value == 'status') {
      //     result.push({ name: 'Unallocated', value: null })
      // }
      result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      return result;
    },
    resetFilters() {
      this.filters = {};
      let keys = this.headers.map((x) => x.value);
      keys.forEach((x) => {
        this.searchHeaders[x] = null;
        this.filters[x] = [];
      });
      this.filteredValues = [];
      this.updateFilterValue++;
      localStorage.removeItem(
        "orderFilters_" + this.$store.state.currentOrg.id
      );
      this.calculateFilteredResults();
    },
    toggleColumn(item, index) {
      item.show = !item.show;
      this.headers[index].show = item.show;
      if (this.selectedHeaders.map((x) => x.value).includes(item.value)) {
        this.selectedHeaders = this.selectedHeaders.filter(
          (x) => x.value != item.value
        );
      } else {
        this.selectedHeaders.push(item);
      }
      let visible = this.selectedHeaders.map((x) => x.value);
      this.headers.forEach((x) => {
        if (visible.includes(x.value)) {
          x.show = true;
        } else {
          x.show = false;
        }
      });
      this.columnKey++;
      this.headerKey++;
    },
    updateUserLayout() {
      localStorage.setItem(
        "orderHeaders",
        JSON.stringify(this.selectedHeaders)
      );
      this.columnMenu = false;
    },
    updateSearchKey() {
      this.searchKey++;
    },
    viewOrder(item) {
      this.$router.push({
        path: "/order/" + item.systemReference,
      });
    },
    viewItem(e, item) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.selectedOrderItem = item;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
  },
};
</script>
  
  <style scoped>
.container {
  width: 100%;
  height: 70vh;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadingContainer {
  width: 100%;
  height: 550px;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>